import { mapListingFunctions } from "@/ts/mapTemplateHelper";
import { LatLngModel } from "../maps/latLngModel";
import { SurgaIconModel } from "./surgaIcons";
import { SearchFilters } from "../searchFiltersModel";

export class SurgaMapProperty {
  public AltWebUrl: string = "";
  public ListingType: string = "";
  public DisplayAddress: boolean = true;
  public ImagesId: string = "";
  public Latitude: number = 0;
  public Longitude: number = 0;
  public IconsId: string = "";
  public SiteName: string = "";
  public StateName: string = "";
  public StreetName: string = "";
  public StreetNumber: string = "";
  public SuburbName: string = "";
  public SurgaWebListingId: string = "";
  public CityName: string = "";
  public PostCode: string = "";
  public Title: string = "";
  public PriceMax: number = 0;
  public PriceMin: number = 0;
  public RentMax: number = 0;
  public RentMin: number = 0;
  public RentPerUnitMax: number = 0;
  public RentPerUnitMin: number = 0;
  public SaleBldAreaMax: number = 0;
  public SaleBldAreaMin: number = 0;
  public RentBldAreaMax: number = 0;
  public RentBldAreaMin: number = 0;
  public BldAreaUOM: string = "sqm";
  public LandAreaConverted: number = 0;
  public LandAreaUOM: string = "sqm";
  public PropertyType: string = "";
  public RentPeriod: string = "";
  public RteTextSale: string = "";
  public RteTextRent: string = "";
  public DisplayPrice: boolean = true;
  public DisplayRent: boolean = true;
  public ShowInPlaceofPrice: string = "";
  public ShowInPlaceofRent: string = "";
  public RentTaxType: string = "";
  private website_isFilterDisplay = true;
  private website_rentPerAnnumMax: number = 0;
  private website_rentPerAnnumMin: number = 0;
  public website_icons: SurgaIconModel[] = [];
  [key: string]: any;

  constructor(property: Record<string, any>, rteText: string) {
    Object.keys(this).forEach((key) => {
      const parameterName = key.charAt(0).toLowerCase() + key.slice(1);
      if (property[parameterName] != null) {
        this[key] = property[parameterName];
      }
    });
    this.setupRteText(rteText);
    this.setupRentPerAnnum();
    this.setupIcons();
  }
  private setupRteText(rteText: string) {
    if (this.ListingType.toLowerCase().includes("v") && this.ListingType.toLowerCase().includes("i")) {
      this.RteTextSale = mapListingFunctions(this,"vi").generateText(rteText);
    } else if (this.ListingType.toLowerCase().includes("v")){
      this.RteTextSale = mapListingFunctions(this,"v").generateText(rteText);
    } else if (this.ListingType.toLowerCase().includes("i")){
      this.RteTextSale = mapListingFunctions(this,"i").generateText(rteText);
    }
    if (this.ListingType.toLowerCase().includes("l")) {
      this.RteTextRent = mapListingFunctions(this,"l").generateText(rteText);
    }
  }
  public getAddressTitle(): string {
    let addressTitle = "";
    if (this.SiteName !== "") {
      addressTitle += ", " + this.SiteName;
    }
    if (this.Title !== "") {
      addressTitle += ", " + this.Title;
    }
    addressTitle += ", " + this.StreetNumber + " " + this.StreetName;
    if (this.SuburbName !== "") {
      addressTitle += ", " + this.SuburbName;
    } else if (this.StateName !== "") {
      addressTitle += ", " + this.StateName;
    }
    addressTitle = addressTitle.substring(1);
    return addressTitle.trim();
  }
  public getAddressString(): string {
    let returnedAddress: string = "";
    returnedAddress += this.addSegment(this.SiteName);
    returnedAddress += this.addSegment(this.Title);
    returnedAddress += this.addSegment(this.StreetNumber);
    returnedAddress += this.addSegment(this.StreetName);
    returnedAddress += this.addSegment(this.SuburbName);
    returnedAddress += this.addSegment(this.StateName);
    if (returnedAddress.length > 0) {
      returnedAddress = returnedAddress.slice(0, -1);
    }
    return returnedAddress;
  }

  private addSegment(segment: string) {
    if (segment && segment != "") {
      segment = segment.replaceAll(/[^a-zA-Z0-9]/g, "-") + "-";
      while (segment.includes("--")) {
        segment = segment.replaceAll("--", "-");
      }
      return segment;
    } else {
      return "";
    }
  }

  private setupRentPerAnnum(): void {
    switch (this.RentPeriod.toLowerCase()) {
      case "monthly":
      case "m":
        {
        this.website_rentPerAnnumMax = this.RentPerUnitMax * 12;
        this.website_rentPerAnnumMin = this.RentPerUnitMin * 12;
        return;
        }
      case "quarterly":
      case "q":
        {
        this.website_rentPerAnnumMax = this.RentPerUnitMax * 4;
        this.website_rentPerAnnumMin = this.RentPerUnitMin * 4;
        return;
        }
      case "fortnightly":
      case "f":
        {
        this.website_rentPerAnnumMax = this.RentPerUnitMax * 26;
        this.website_rentPerAnnumMin = this.RentPerUnitMin * 26;
        return;
        }
      case "weekly":
      case "w":
        {
        this.website_rentPerAnnumMax = this.RentPerUnitMax * 52;
        this.website_rentPerAnnumMin = this.RentPerUnitMin * 52;
        return;
        }
      default:
        {
        this.website_rentPerAnnumMax = this.RentMaxPerUnit;
        this.website_rentPerAnnumMin = this.RentMinPerUnit;
        return;
        }
    }
  }

  public getLatLng(): LatLngModel {
    let coordinates: LatLngModel = new LatLngModel(this.Latitude, this.Longitude);
    return coordinates
  }
  public setFilterDisplay(propertyType: string, searchOptions: SearchFilters, isUnitSearch: boolean) {
    if (!this.ListingType.includes(propertyType) && !(propertyType == "V" && this.ListingType.includes("I"))) {
      this.website_isFilterDisplay = false;
      return;
    }
    if (searchOptions.propertyType !== "" && !this.PropertyType.toLowerCase().includes(searchOptions.propertyType.toLowerCase())) {
      this.website_isFilterDisplay = false;
      return;
    }
    if (propertyType == "L") {
      if (isUnitSearch) {
        if (!this.manageMaxMin(this.website_rentPerAnnumMax, this.website_rentPerAnnumMin, searchOptions.maxPrice, searchOptions.minPrice)) {
          this.website_isFilterDisplay = false;
          return;
        }
      } else {
        if (!this.manageMaxMin(this.RentMax, this.RentMin, searchOptions.maxPrice, searchOptions.minPrice)) {
          this.website_isFilterDisplay = false;
          return;
        }
      }
    } else {
      if (!this.manageMaxMin(this.PriceMax, this.PriceMin, searchOptions.maxPrice, searchOptions.minPrice)) {
        this.website_isFilterDisplay = false;
        return;
      }
    }
    if (propertyType == "L") {
      if (!this.manageMaxMin(this.RentBldAreaMax, this.RentBldAreaMin, searchOptions.maxArea, searchOptions.minArea)) {
        this.website_isFilterDisplay = false;
        return;
      }
    } else {
      if (!this.manageMaxMin(this.SaleBldAreaMax, this.SaleBldAreaMin, searchOptions.maxArea, searchOptions.minArea)) {
        this.website_isFilterDisplay = false;
        return;
      }
    }
    this.website_isFilterDisplay = true;
  }
  private manageMaxMin(maxVal: number, minVal: number, maxFilter: number, minFilter: number): boolean {
    if (minFilter == -1 && maxFilter == -1) {
      return true;
    }
    if (minVal == 0 && maxVal == 0) {
      return false;
    }
    if (minVal == 0) {
      minVal = maxVal;
    } else if (maxVal == 0) {
      maxVal = minVal;
    }
    if (minFilter == -1) {
      if (maxFilter >= minVal) {
        return true;
      }
    } else if (maxFilter == -1) {
      if (minFilter <= maxVal) {
        return true;
      }
    } else {
      if ((minFilter <= maxVal && maxVal <= maxFilter) || (minFilter <= minVal && minVal <= maxFilter) || (minVal <= minFilter && maxVal >= maxFilter)) {
        return true;
      }
    }
    return false;
  }
  public isDisplayed(): boolean {
    return this.website_isFilterDisplay;
  }

  public setupIcons(): void {
  }
}
