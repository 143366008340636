let globalVariables: Record<string, any> = {};
// determines if system is running on live servers or staging servers.
function globalValues() {
  // initiates global values.
  function initiate(): void {
    const pageSettings = document.getElementsByTagName("page-settings")[0];
    try {
      globalVariables = JSON.parse(pageSettings.getAttribute("global-variables"));
    } catch (ex) { }
  }

  function getDefaultIfEmpty(parameterName: string, defaultReturnValue: any = ""): any {
    let result: string = this.get(parameterName, defaultReturnValue);
    if (result == "") {
      return defaultReturnValue;
    } else {
      return result;
    }
  }
  // retrieves global variable based on parameter. Returns default value if value has not loaded.
  function get(parameterName: string, defaultReturnValue: any = ""): any {
    if (parameterName in globalVariables) {
      return globalVariables[parameterName];
    } else {
      return defaultReturnValue;
    }
  }
  function getBoolean(parameterName: string): boolean {
    const boolValue: string = get(
      parameterName, "False"
    );
    if (boolValue === "True") {
      return true;
    } else {
      return false;
    }
  }
  return { initiate, get, getDefaultIfEmpty, getBoolean };
}
export { globalValues };
