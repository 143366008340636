import { location } from "../ts/locationComp";
let queryString: string = window.location.search;
let paramManager: URLSearchParams = new URLSearchParams(queryString);
function parameterHelperComp() {
  function resetParameters() {
    queryString = window.location.search;
    paramManager = new URLSearchParams(queryString);
  }
  function setParameters(parameters: Record<string, any>): void {
    for (let key in parameters) {
      paramManager.set(key, parameters[key]);
    }
  }

  function setParameter(parameterName: string, parameterValue: any): void {
    let allParameters: Record<string, any> = getParameters();
    allParameters[parameterName] = parameterValue;
    resetParameters();
    setParameters(allParameters);
  }


  function deleteParameters(parameters: string[]): void {
    for (let parameterName of parameters) {
      paramManager.delete(parameterName);
    }
  }

  function getParameters(): Record<string, any> {
    let pQueryString: string = "";
    // if it is a location page, initiate location based on location value, otherwise load search values.
    if (location().isLocation()) {
      pQueryString = location().getParameters();
    } else {
      pQueryString = window.location.search;
    }
    const pParamManager = new URLSearchParams(pQueryString);
    let result: Record<string, any> = {};
    pParamManager.forEach(function (value, key) {
      result[key] = value.replaceAll("#", "");
    });
    return result;
  }
  function getParameter(parameter: string): string {
    let allParameters: Record<string, any> = getParameters();
    return allParameters[parameter];
  }

  function pushParameters() {
    let parameterString: string = paramManager.toString();
    if (parameterString.length > 0) {
      window.history.replaceState(null, null, "?" + paramManager.toString());
    } else {
      window.history.replaceState(null, null, window.location.pathname);
    }
  }
  return {
    resetParameters,
    setParameters,
    deleteParameters,
    pushParameters,
    getParameters,
    getParameter,
    setParameter
  };
}
export { parameterHelperComp };
