let region: string = document
  .getElementsByTagName("page-settings")[0]
  .getAttribute("data-region");
if (region == "Eng") {
  region = "UK";
}
function getLocaleCurrency(currency: number): string {
  currency = Math.round(currency);
  let formatter: Intl.NumberFormat;
  if (region == "UK") {
    formatter = new Intl.NumberFormat("en-GB", {
      currency: "GBP",
      minimumFractionDigits: 0,
      style: "currency"
    });
  } else {
    formatter = new Intl.NumberFormat("en-US", {
      currency: "USD",
      minimumFractionDigits: 0,
      style: "currency"
    });
  }
  return formatter.format(currency);
}
function getLocaleNumber(unformatNumber: number, decimals: number): string {
  unformatNumber = Math.round(unformatNumber);
  let formatter: Intl.NumberFormat;
  if (region == "UK") {
    formatter = new Intl.NumberFormat("en-GB");
  } else {
    formatter = new Intl.NumberFormat("en-US");
  }
  return formatter.format(unformatNumber);
}

function getLocaleSurgaUrl(): string {
  if (region == "UK") {
    return "https://www.surgacentral.co.uk/";
  } else {
    return "https://www.surgacentral.com/";
  }
}
function getLocaleForLease(): string {
  if (region == "UK") {
    return "To Let";
  } else {
    return "For Lease";
  }
}
function getLocaleForLeaseShort(): string {
  if (region == "UK") {
    return "Let";
  } else {
    return "Lease";
  }
}
function getLocaleArea(): string {
  if (region == "UK") {
    return " sqft";
  } else {
    return "m2";
  }
}
function getLocaleNumberFormat(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function getSuburbForSearch(suburb: string): string {
  if (region == "UK") {
    let newSuburb: string[] = suburb.split(" ");
    return newSuburb[0];
  } else {
    return suburb;
  }
}
function getPAorPAX(isExclusive: boolean = false): string {
  if (region == "UK" && isExclusive) {
    return "PAX";
  } else {
    return "PA";
  }
}
function getBldArea(): string {
  if (region == "UK") {
    return "sqft";
  } else {
    return "sqm";
  }
}
function getLocaleForLeased(): string {
  if (region == "UK") {
    return "Let";
  } else {
    return "Leased";
  }
}
function getLocaleCountryCode(): string {
  if (region == "UK") {
    return "uk";
  } else if (region == "Viet") {
    return "vn";
  } else if (region == "Nz") {
    return "nz";
  } else {
    return "au";
  }
}
function getCountryCode(): string {
  if (region == "UK") {
    return "GB";
  } else if (region == "Viet") {
    return "VN";
  } else if (region == "Nz") {
    return "NZ";
  } else {
    return "AU";
  }
}
function getBaseCurrency(): string[] {
  switch (region) {
    case "Aus":
      return ["AUD", "$"]
    case "UK":
      return ["GBP", "£"]
    case "Viet":
      return ["USD", "$"]
    case "Nz":
      return ["NZD", "$"]
    default:
      return ["AUD", "$"]
  }
}
export { getLocaleForLeaseShort, getLocaleCurrency, getLocaleArea, getLocaleForLease, getSuburbForSearch, getPAorPAX, getBldArea, getLocaleForLeased, getCountryCode, getLocaleCountryCode, getLocaleSurgaUrl, getLocaleNumber, getBaseCurrency, getLocaleNumberFormat };
