import { Image } from "./models/imageModel";

// Helps manage images and image data sent from Umbraco.
function imageHelper() {
  // Retrieved correct resized image URL.
  function getResizedImage(images: Image[], clientWidth: number): string {
    try {
      images.sort((a, b) => (a.Width > b.Width ? 1 : -1));
      for (const image of images) {
        if (image.Width > clientWidth) {
          return image.Path;
        }
      }
      if (images.length > 0) {
        return images[images.length - 1].Path;
      } else {
        return "/assets/ImageNotFound.png";
      }
    } catch {
      return "/assets/ImageNotFound.png";
    }
  }
  return { getResizedImage };
}
export { imageHelper };
